import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import GKGuruPage from '../../components/Games/Trivia/GKGuru/GKGuru'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  GAMES,
  TRIVIA,
  GKGURU as GKGURU_LINK,
} from '../../components/internal-links'
import { GKGURU } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Trivia',
    url: TRIVIA,
  },
  {
    title: 'GK Quiz',
    url: GKGURU_LINK,
  },
]

const GKGuru: React.FC = () => {
  const [gkFaqsSt, setGkFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ gkFaqs }) =>
      setGkFaqsSt(gkFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={GKGURU}>
      <SEO
        title="Play Online GK (General Knowledge) Quiz to Earn Money"
        description="Use your General Knowledge skills to win real cash. Download Mega Android app. Answer fast to beat your opponents. Play GK quizzes & earn real money prizes. 24x7."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(gkFaqsSt)}
      />
      <GKGuruPage />
    </Layout>
  )
}

export default GKGuru
