import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const GKGuruInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about GK</h2>
      <p>
        Do you itch to answer every question the fastest? Do you find yourself
        screaming the right answers at the screen, before the options pop up?
        Success in life might be about being at the right place at the right
        time. But success in Mega GK is always about answering it right within
        the right time.
      </p>
      <p>
        Mega brings the hot seat to you — the chance to answer GK questions, and
        earn real money rewards. Is your brain bursting with random cricket and
        Bollywood trivia? Can you still crack your high school geography? Put
        your superb reflexes and curiosity to work. Defeat your opponents with
        your speed and smarts in our GK quiz and earn money.
      </p>
      <p>
        A number of online apps offer trivia contests and online GK tests to
        earn money, but that’s only for a limited time in a day. Also, the
        prizes get divided across a large number of participants on different
        leaderboards. That doesn’t sound fair!
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Surely there must be a better way to play trivia games and a real chance
        to win bigger! No worries, we have you covered. Mega GK is where you
        play online GK quizzes to earn real money instantly. No bots. No
        waiting. No division of money. Answer GK questions fast and beat than
        your opponents in short, quick quizzes to win real cash every minute!
      </p>
      <p>
        The general knowledge questions on Mega are fair, fun and cover regular,
        everyday affairs. So if you follow cricket, love Bollywood films or
        check the news sometimes, then this is exactly what you are looking for!
        Download Mega on your Android device. Play with real players, 24x7. Beat
        your opponents in GK quizzes and earn real money.
      </p>

      <h3>How to Play</h3>
      <ul>
        <li>
          <p>Download Mega. Choose GK under the Trivia category.</p>
        </li>
        <li>
          <p>
            Pick a contest. The buy-in amounts go as low as ₹1, so you can play
            comfortably at any contest of your size. All games are played in
            multiplayer mode between 2-3 players in real-time.
          </p>
        </li>
        <li>
          <p>
            Answer fast. Answer right. Beat your opponents and sweep the prize.
            Play any number of rounds on the table.
          </p>
        </li>
        <li>
          <p>
            The winnings are credited to your winnings wallet as soon as you
            leave the table. Withdraw seamlessly.
          </p>
        </li>
        <li>
          <p>
            Share the joy. Go tell your friends how you won an online GK quiz to
            earn some real money prizes!
          </p>
        </li>
      </ul>

      <h3>Rules</h3>
      <ul>
        <li>
          <p>
            All players start with 4 points. To win, players must ‘race to zero’
            - reduce points and reach 0.
          </p>
        </li>
        <li>
          <p>
            All questions are in a multiple choice format. Tap the right answer
            faster than your opponents to reduce a point.
          </p>
        </li>
        <li>
          <p>
            Only the fastest player with the correct answer reduces their point
            by 1 for each question.
          </p>
        </li>
        <li>
          <p>Answers enough questions to reach 0 the fastest and win.</p>
        </li>
        <li>
          <p>
            When a player wins, the remaining players’ points are pooled
            together, and multiplied by the point rate for the total prize
            amount.
          </p>
        </li>
        <li>
          <p>
            Winner gets the total pool, after deduction of the rake amount (a
            tiny percentage of the entire amount).
          </p>
        </li>
      </ul>

      <h3>Multiplayer games. 24x7</h3>
      <p>
        GK on Mega is always played in a multiplayer format, against 100%
        verified profiles. Only 2-3 players are allowed on each table. The
        tables run 24x7, so you don’t have to wait to play your favourite GK
        quiz online and earn real money.
      </p>

      <h3>Smart games. Fun questions.</h3>
      <p>
        The Trivia games on Mega are truly knowledge and skill-based. A large
        variety of questions and a smooth, lightweight interface helps you play
        reliably. The topics included are Cricket, Bollywood, Geography and
        more. So a keen eye on everyday affairs is all you need to master the
        game.
      </p>

      <h3>Instant Wins. Instant Withdrawals.</h3>
      <p>
        You don’t have to wait days, hours or even minutes to win GK quiz games
        and earn money. The total wins of each round are instantly transferred
        to your Winnings wallet, the moment you leave the table. Transfer the
        winnings easily to your Paytm wallet, through UPI or directly to your
        bank account, all within a minute. Fastest finger first. Right?
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections visible={visible} hidden={hidden} gameName="GK" />
    </div>
  )
}

export default GKGuruInfo
